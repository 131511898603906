.flex {
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    width: min(calc(100% - 40px), 1400px);
    @media (min-width: 769px) {
        align-items: flex-start;
        margin-top: 20px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.switchButtonWrapper {
    justify-self: center;
    align-self: center;
    @media (min-width: 769px) {
        align-self: flex-start;
        margin-top: 70px;
        transform: rotate(90deg);
    }
    margin: 4px;
}

.transliteration {
/*    color: grey; */
    word-break: break-all;
    margin-bottom: 0;
}

.translationHeaderContainer {
    display: flex;
    padding: 0 10px;
    flex-direction: row;
    height: 38px;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    @media (max-width: 768px) {
        padding: 0 8px;
    }
}

.label {
    font-size: 0.9rem;
}

.translationFieldContainer {
    display: flex;
    flex-direction: column;
    @media (min-width: 769px) {
        width: calc(50% - 20px);
    }
    @media (max-width: 768px) {
        max-width: 100%;
    }
}

.removeButton {
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.translationHeader {
    height: 38px;
    min-height: 38px;
    margin: 0 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.sourceInput {
	min-height: 171px;
}

.translationOutput {
/*    background-color: #FFF5F7; */
    border-radius: 1;
    height: 100%;
    min-height: 171px;
    overflow-wrap: break-word;
    padding: 16px;
}

.networkError {
    display: grid;
    justify-items: center;
}

.loadingBar {
    top: 4px;
    margin-top: -4px;
}

.translationText {
    font-weight: bold;
}

.asrTempOutput {
    width: 100%;
    margin: 0 10px;
    text-align: end;
    overflow-x: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: row-reverse;
    font-size: small;
}
