.activeAnimation {
    background: linear-gradient(180deg, #ffffff00 49%, rgba(33, 150, 243, 1) 50%);
    animation: activeAnimationKeyframes 3s ease infinite alternate;
    background-size: 200% 200%;
}

@keyframes activeAnimationKeyframes {
    0% {
        background-position: 100% 50%;
    }
    10% {
        background-position: 100% 100%;
    }
    20% {
        background-position: 100% 50%;
    }
    30% {
        background-position: 100% 80%;
    }
    40% {
        background-position: 100% 50%;
    }
    50% {
        background-position: 100% 70%;
    }
    60% {
        background-position: 100% 100%;
    }
    70% {
        background-position: 100% 50%;
    }
    80% {
        background-position: 100% 90%;
    }
    90% {
        background-position: 100% 40%;
    }
    100% {
        background-position: 100% 80%;
    }
}
