.languageContainer {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: bold;

    filter: saturate(0.9); // flag color adjustment
}

.languageName{
    font-weight: bold;
}
