.logo {
    height: 80px;
    margin-right: 10px;
}

.atChar {
    height: '.8em';
}

.chip {
    margin: 3px;
}

.content {
    a:not(.chip) {
        color: #d22d40;
    }
}
